import CardPickup from '~/components/Card/CardPickup';
import CarouselSlider from '~/components/Carousel/Carousel';

type CardProps = {
  image: string;
  title: string;
  description: string;
  url: string;
};

export const Carousel = ({ data }: { data: CardProps[] }) => {
  return (
    <CarouselSlider
      slides={data.map((data, index) => (
        <CardPickup
          key={index}
          url={data.url}
          imageUrl={data.image}
          title={data.title}
          description={data.description}
        />
      ))}
    />
  );
};
