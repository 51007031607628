import CardCoCreation from '~/components/Card/CardCoCreation';

type DataT = {
  bannerText: string;
  description: string;
  descriptionLink: string;
  leadOrg: { src: string };
  partnerOrgs: { src: string }[];
};

export const Partner = ({ data }: { data: DataT[] }) => {
  return (
    <div className='flex items-center justify-center bg-highlight'>
      <div className='my-10 flex flex-row flex-nowrap gap-4 overflow-scroll lg:flex-wrap'>
        {data.map((data, index) => (
          <CardCoCreation
            key={index}
            bannerText={data.bannerText}
            description={data.description}
            descriptionLink={data.descriptionLink}
            leadOrg={data.leadOrg}
            partnerOrgs={data.partnerOrgs}
          />
        ))}
      </div>
    </div>
  );
};
