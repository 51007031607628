import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

interface CardPickupProps {
  imageUrl: string;
  title: string;
  description: string;
  url: string;
}

const CardPickup: React.FC<CardPickupProps> = ({ imageUrl, title, description, url }) => {
  return (
    <Link href={url}>
      <div className='order-1 mx-auto flex max-w-[640px] flex-none flex-col items-start gap-4 p-0 lg:gap-6'>
        <div className='relative h-[240px] w-full max-w-[640px] overflow-hidden rounded-corner-l lg:h-[320px]'>
          <Image src={imageUrl} alt={title} fill={true} objectFit={'cover'} />
        </div>
        <h2 className='line-clamp-2 text-title-strong-large__sp text-accent lg:text-title-strong-large'>
          {title}
        </h2>
        <p className='line-clamp-2 text-body-large'>{description}</p>
      </div>
    </Link>
  );
};

export default CardPickup;
