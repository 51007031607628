import { useMemo } from 'react';

import { ApiV2Challenge } from '~/api/v2/challenges/get-type';
import { CardProjectDetail } from '~/components/Card/CardProjectDetail';
import SectionHeader from '~/components/SectionHeader/SectionHeader';

const Card = ({ challenge }: { challenge: ApiV2Challenge }) => {
  console.log(challenge)
  const name = useMemo(() => {
    if (challenge.owner_type === 'Corporate') {
      return challenge.owner_corporate?.name;
    } else {
      return challenge.owner_organization?.name;
    }
  }, [challenge.owner_corporate, challenge.owner_organization, challenge.owner_type]);

  return (
    <CardProjectDetail
      link={`/challenges/${challenge.id}`}
      areaText={challenge.area}
      companyName={name}
      companyLogo={
        challenge.owner_corporate?.logo ||
        challenge.owner_organization?.logo ||
        '/icons/corp-icon.png'
      }
      text={challenge.description}
      title={challenge.title}
      src={challenge.images[0]}
      label={'チャレンジマーケット'}
      status={'募集中'}
      recruitmentList={challenge.needs.map((n) => n.name)}
    />
  );
};

export const Challenge = ({ challenges }: { challenges: ApiV2Challenge[] }) => {
  return (
    <div className='flex flex-col items-center gap-8'>
      <SectionHeader
        title='チャレンジマーケット'
        description='社会を変えるユニークな活動に参加しよう'
        href='/challenges'
      />
      <div className='flex flex-col flex-wrap justify-center gap-4 lg:flex-row'>
        {challenges.map((challenge, i) => (
          <Card key={i} challenge={challenge} />
        ))}
      </div>
    </div>
  );
};
