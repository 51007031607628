import useSWR from 'swr';

import { SWR_GLOBAL_CONFIG } from '~/api/_constant';
import { request } from '~/api/_utils/request';

import { ApiV2Organization } from './get-type';

const getPath = (limit?: number) =>
  limit ? `/v2/organizations?limit=${limit}` : `/v2/organizations`;

export async function apiGetV2Organizations(limit?: number) {
  const response = await request.GET<{ organizations: ApiV2Organization[] }>(getPath(limit));
  return response.organizations;
}

export function useSWRV2Organizations(limit?: number) {
  const swr = useSWR(
    () => getPath(limit),
    () => apiGetV2Organizations(limit),
    SWR_GLOBAL_CONFIG,
  );
  return { ...swr, organizations: swr.data };
}
