import Link from 'next/link';
import React from 'react';
import { BiSync } from 'react-icons/bi';

import { Avatar } from '../Avatar/Avatar';

interface Org {
  src: string;
}

interface CardCoCreationProps {
  bannerText: string;
  description: string;
  descriptionLink: string;
  leadOrg: Org;
  partnerOrgs: Array<Org>;
}

export const CardCoCreation: React.FC<CardCoCreationProps> = ({
  bannerText,
  description,
  descriptionLink,
  leadOrg,
  partnerOrgs,
}) => {
  return (
    <div className='w-[320px] shrink-0 lg:w-[348px]'>
      <div className='bg-white p-4 shadow-elevation-level1'>
        <div className='rounded-corner-s bg-blue-80 p-1.5 text-center text-body-strong-small text-white'>
          <span>{'\\　' + bannerText + '　/'}</span>
        </div>
        <div className='bg-white pt-3'>
          <Link href={descriptionLink}>
            <p className='line-clamp-2 cursor-pointer text-body-strong-large leading-6 text-gray-90 hover:underline'>
              {description}
            </p>
          </Link>
        </div>
      </div>
      <div className='mt-6 flex items-center justify-center'>
        <Avatar size={64} src={leadOrg.src ?? '/icons/corp-icon.png'} />
        <BiSync className='mx-2 h-5 w-5 text-blue-80' />
        {partnerOrgs.slice(0, 3).map((org, index) => (
          <div
            key={org.src}
            className={`relative ${index !== 0 ? '-ml-4' : ''}`}
            style={{ zIndex: 4 - index }}
          >
            <Avatar src={org.src ?? '/icons/corp-icon.png'}  size={64} />
          </div>
        ))}
        {partnerOrgs.length > 3 && (
          <div className='relative z-10 -ml-4 flex items-center justify-center'>
            <div className='flex h-16 w-16 items-center justify-center rounded-corner-pill border border-base'>
              <span className='text-title-strong-x-small text-secondary'>
                +{partnerOrgs.length - 3}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardCoCreation;
