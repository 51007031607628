import useSWR from 'swr';

import { SWR_GLOBAL_CONFIG } from '~/api/_constant';
import { request } from '~/api/_utils/request';

import { ApiV2SocialIssueCategory } from './get-type';

const PATH = '/v2/social_issue_categories';

type Response = {
  social_issue_categories: ApiV2SocialIssueCategory[];
};

export async function apiGetV2SocialIssueCategories() {
  const response = await request.GET<Response>(PATH);
  return response.social_issue_categories;
}

export function useSWRV2SocialIssueCategories() {
  const swr = useSWR(PATH, apiGetV2SocialIssueCategories, SWR_GLOBAL_CONFIG);
  return { ...swr, socialIssueCategories: swr.data };
}
