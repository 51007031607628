import Link from 'next/link';
import React from 'react';

import { Button } from '../Button/Button';

interface SectionHeaderProps {
  title: string;
  description: string;
  href: string;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({ title, description, href }) => {
  return (
    <div className='flex w-full items-center justify-between lg:w-[856px]'>
      <div className='flex flex-col items-start'>
        <Link href={href}>
          <div className='h-[46px] text-title-strong-medium text-accent'>{title}</div>
        </Link>
        <p className='text-body-medium text-regular'>{description}</p>
      </div>
      <div className='hidden lg:block'>
        <Button
          link={{ href: href }}
          isShowArrowIcon={true}
          variant='subtle'
          size='large'
          isIconOnly={false}
        >
          もっとみる
        </Button>
      </div>
    </div>
  );
};

export default SectionHeader;
