import MainBanners from './mainBanners';

export const AboveBanners = () => {
  return (
    <MainBanners
      banners={[
        {
          src: '/images/top/banners/1.png',
          alt: 'ビギナー用バナー（NPO・社会的事業者向け）',
          link: 'https://veiled-copper-2cc.notion.site/fdf66e83d9c84663906498736b6f4987?v=5362b4a7c756443b928fe5d39825045a&pvs=4',
          target: '_blank',
        },
        {
          src: '/images/top/banners/2.png',
          alt: 'ビギナー用バナー（企業向け）',
          link: 'http://veiled-copper-2cc.notion.site/',
          target: '_blank',
        },
      ]}
    />
  );
};
