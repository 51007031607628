import Link from 'next/link';
import React from 'react';
import { BiChevronRight } from 'react-icons/bi';

interface MenuCategoryItemProps {
  title: string;
  href: string;
}

const MenuCategoryItem: React.FC<MenuCategoryItemProps> = ({ title, href }) => {
  return (
    <Link
      href={href}
      className='flex h-11 w-56 items-center justify-between p-0 text-primary no-underline'
    >
      <p className='flex-grow text-body-strong-medium leading-7'>{title}</p>
      <div className='flex h-5 w-5 items-center justify-center'>
        <BiChevronRight size={20} />
      </div>
    </Link>
  );
};

export default MenuCategoryItem;
