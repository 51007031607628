import { ApiV2Organization } from '~/api/v2/organizations/get-type';
import { CardOrg } from '~/components/Card/CardOrg';
import SectionHeader from '~/components/SectionHeader/SectionHeader';

const Card = ({ organization }: { organization: ApiV2Organization }) => {
  return (
    <CardOrg
      link={`/orgs/${organization.uid}`}
      companyName={organization.name}
      title={organization.mission}
      areaText={organization.area}
      categoryList={organization.tags?.map((tag) => tag.name) || []}
      companyLogo={organization.logo || '/icons/corp-icon.png'}
    />
  );
};

export const Spo = ({ organizations }: { organizations: ApiV2Organization[] }) => {
  return (
    <div className='flex flex-col items-center gap-6'>
      <SectionHeader
        title='NPO・社会的事業者'
        description='参加しているNPO・社会的事業者についてを知ろう'
        href='/orgs'
      />
      <div className='flex flex-col flex-wrap justify-center gap-4 lg:flex-row'>
        {organizations.map((organization) => (
          <div key={organization.id} style={{ maxWidth: '202px' }}>
            <Card key={organization.id} organization={organization} />
          </div>
        ))}
      </div>
    </div>
  );
};
