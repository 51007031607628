import { ApiV2Corporate } from '~/api/v2/corporates/get-type';
import { CardOrg } from '~/components/Card/CardOrg';
import SectionHeader from '~/components/SectionHeader/SectionHeader';

const Card = ({ corporate }: { corporate: ApiV2Corporate }) => {
  return (
    <CardOrg
      link={`/corps/${corporate.uid}`}
      companyName={corporate.name}
      companyLogo={corporate.logo ?? '/icons/corp-icon.png'}
      title={corporate.social_vision}
      areaText={''}
      categoryList={[corporate.materiality]}
    />
  );
};

export const Corp = ({ corporates }: { corporates: ApiV2Corporate[] }) => {
  return (
    <div className='flex flex-col items-center gap-6'>
      <SectionHeader title='企業' description='参加している企業について知ろう' href='/corps' />
      <div className='flex flex-col flex-wrap justify-center gap-4 lg:flex-row'>
        {corporates.map((corporate) => (
          <div key={corporate.id} style={{ maxWidth: '202px' }}>
            <Card key={corporate.id} corporate={corporate} />
          </div>
        ))}
      </div>
    </div>
  );
};
