import useSWR from 'swr';

import { SWR_GLOBAL_CONFIG } from '~/api/_constant';
import { request } from '~/api/_utils/request';

import { ApiV2Challenge } from './get-type';

const getPath = (limit?: number) => (limit ? `/v2/challenges?limit=${limit}` : '/v2/challenges');

export async function apiGetV2Challenges(limit?: number) {
  const response = await request.GET<{ challenges: ApiV2Challenge[] }>(getPath(limit));
  return response.challenges;
}

export function useSWRV2Challenges(limit?: number) {
  const swr = useSWR(
    () => getPath(limit),
    () => apiGetV2Challenges(limit),
    SWR_GLOBAL_CONFIG,
  );
  return { ...swr, challenges: swr.data };
}
