import { useMemo } from 'react';

import { CardActivityReportDetail } from '~/components/Card/CardActivityReportDetail';
import SectionHeader from '~/components/SectionHeader/SectionHeader';
import { PostT } from '~/state/post';

const Card = ({ post }: { post: PostT }) => {
  const src = useMemo(() => {
    if (post.content_image) {
      return post.content_image;
    }

    if (post.postable_corporate?.logo) {
      return post.postable_corporate.logo;
    }

    if (post.postable_organization?.logo) {
      return post.postable_organization.logo;
    }
  }, [post]);

  const link = useMemo(() => {
    if (post.postable_corporate) {
      return `/corps/${post.postable_corporate.uid}/${post.id}`;
    }

    if (post.postable_organization) {
      return `/orgs/${post.postable_organization.uid}/${post.id}`;
    }

    return `/posts/${post.id}`;
  }, [post]);

  return (
    <CardActivityReportDetail
      link={link}
      areaText={''}
      categoryList={post.social_issue ? [post.social_issue.name] : []}
      text={post.detail ?? ''}
      title={post.title}
      src={src}
    />
  );
};

export const Posts = ({ posts }: { posts: PostT[] }) => {
  return (
    <div className='flex flex-col items-center gap-8'>
      <SectionHeader
        title='活動報告'
        description='企業やNPO・社会的事業者の活動や想いについて知ろう'
        href='/activities'
      />
      <div className='flex flex-col flex-wrap justify-center gap-4 lg:flex-row'>
        {posts.map((post, i) => (
          <div key={i} style={{ maxWidth: '267.67px', width: '267.67px' }}>
            <Card key={i} post={post} />
          </div>
        ))}
      </div>
    </div>
  );
};
