import useSWR from 'swr';

import { SWR_GLOBAL_CONFIG } from '~/api/_constant';
import { request } from '~/api/_utils/request';

const getPath = '/v2/impact_cocreations';

export async function apiGetV2ImpactCocreations() {
  const response = await request.GET<{
    competition?: ApiCompetition;
    challenge?: ApiChallenge;
    activity_report?: ApiActivityReport;
  }>(getPath);
  return response;
}

export function useSWRV2ImpactCocreations() {
  const swr = useSWR(getPath, () => apiGetV2ImpactCocreations(), SWR_GLOBAL_CONFIG);
  return { ...swr, impact_cocreations: swr.data };
}

type ApiChallenge = {
  id: number;
  title: string;
  url: string;
  partners: [];
  organizations: {
    id: number;
    uid: string;
    name: string;
    category: string;
    logo?: string;
  }[];
  corporates: {
    id: number;
    uid: string;
    name: string;
    category: string;
    category_postfix: boolean;
    logo?: null;
  }[];
  owner: {
    owner_type: string;
    data: {
      id: number;
      uid: string;
      name: string;
      category: string;
      category_postfix: boolean;
      logo?: null;
    };
  };
};

type ApiCompetition = {
  id: number;
  title: string;
  url: string;
  corporate: {
    id: number;
    uid: string;
    name: string;
    category: string;
    category_postfix: boolean;
    logo?: string;
  };
  organization: {
    id: number;
    uid: string;
    name: string;
    category: string;
    logo?: string;
  };
};

type ApiActivityReport = {
  id: number;
  title: string;
  url: string;
  corporate: {
    id: number;
    uid: string;
    name: string;
    category: string;
    category_postfix: boolean;
    logo?: string;
  };
  organization: {
    id: number;
    uid: string;
    name: string;
    category: string;
    logo?: string;
  };
};
