import useSWR from 'swr';

import { SWR_GLOBAL_CONFIG } from '~/api/_constant';
import { request } from '~/api/_utils/request';

import { ApiCompetition } from './get-type';

const getPath = (limit?: number) =>
  limit ? `/v2/competitions?limit=${limit}` : '/v2/competitions';

export async function apiGetV2Competitions(limit?: number) {
  const response = await request.GET<{ competitions: ApiCompetition[] }>(getPath(limit));
  return response.competitions;
}

export function useSWRV2Competitions(limit?: number) {
  const swr = useSWR(
    () => getPath(limit),
    () => apiGetV2Competitions(limit),
    SWR_GLOBAL_CONFIG,
  );
  return { ...swr, competitions: swr.data };
}
