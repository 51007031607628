import useSWR from 'swr';

import { SWR_GLOBAL_CONFIG } from '~/api/_constant';
import { request } from '~/api/_utils/request';
import { PostT } from '~/state/post';

type Query = {
  page?: number;
};

const PATH = `/v2/posts`;

export async function apiGetV2Posts({ page }: Query) {
  const response = await request.GET<{ posts: PostT[]; total_count: number }>(PATH, { page });
  return {
    posts: response.posts,
    totalCount: response.total_count,
  };
}

export function useSWRV2Posts(page: number = 1) {
  const { data, error, mutate } = useSWR(
    [PATH, page],
    () => apiGetV2Posts({ page }),
    SWR_GLOBAL_CONFIG,
  );

  return {
    posts: data?.posts,
    totalCount: data?.totalCount,
    isLoading: !error && !data,
    isError: error,
    mutate: (page: number) => mutate(() => apiGetV2Posts({ page }), false),
  };
}

// どこからも呼び出されていない関数
export function useSWRV2PostsForTopPage() {
  const fetcher = async () => {
    const results = await Promise.all([apiGetV2Posts({ page: 1 }), apiGetV2Posts({ page: 2 })]);
    return results.flatMap((result) => result.posts);
  };

  const { data, error } = useSWR<PostT[]>(`${PATH}(for_top_page)`, fetcher, SWR_GLOBAL_CONFIG);

  return {
    posts: data,
    isLoading: !error && !data,
    isError: error,
  };
}
