import useSWR from 'swr';

import { SWR_GLOBAL_CONFIG } from '~/api/_constant';
import { request } from '~/api/_utils/request';

import { ApiV2Presentation } from './get-type';

const getPath = (limit?: number) =>
  limit ? `/v2/presentations?limit=${limit}` : '/v2/presentations';

export async function apiGetV2Presentations(limit?: number) {
  const response = await request.GET<{ presentations: ApiV2Presentation[] }>(getPath(limit));
  return response.presentations;
}

export function useSWRV2Presentations(limit?: number) {
  const swr = useSWR(
    () => getPath(limit),
    () => apiGetV2Presentations(limit),
    SWR_GLOBAL_CONFIG,
  );
  return { ...swr, presentations: swr.data };
}
