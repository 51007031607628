import Image from 'next/image';
import Link from 'next/link';
import { useMemo, ComponentProps } from 'react';

import { useSWRV2Challenges } from '~/api/v2/challenges/get';
import { useSWRV2Competitions } from '~/api/v2/competitions/get';
import { useSWRV2Corporates } from '~/api/v2/corporates/get';
import { useSWRV2ImpactCocreations } from '~/api/v2/impact_cocreations/get';
import { useSWRV2Organizations } from '~/api/v2/organizations/get';
import { useSWRV2Posts } from '~/api/v2/posts/get';
import { useSWRV2Presentations } from '~/api/v2/presentations/get';
import { useSWRV2SocialIssueCategories } from '~/api/v2/social_issue_categories/get';
import { CorpFurusatoRecruitmentData } from '~/data/CorpFurusatoRecruitmentData';
import Layout from '~/pages/_layout.page';
import { competitionTitle } from '~/state/competition';
import { isEmpty } from '~/utils/array';

import { AboveBanners } from './toppage/_components/aboveBanners';
import { Area } from './toppage/_components/area';
import { BottomBanners } from './toppage/_components/bottomBanners';
import { Carousel } from './toppage/_components/carousel';
import { Challenge } from './toppage/_components/challenge';
import { Competition } from './toppage/_components/competition';
import { Corp } from './toppage/_components/corp';
import { Partner } from './toppage/_components/partner';
// import { AboveBanners } from './toppage/_components/aboveBanners';
import { Posts } from './toppage/_components/posts';
import { Presentation } from './toppage/_components/presentation';
import { SearchSpo } from './toppage/_components/searchSpo';
// import { SideBanners } from './toppage/_components/sideBanners';
import { Spo } from './toppage/_components/spo';

import IMG_INTILAQ from '@public/images/intilaq.png';

const RECRUITMENT = CorpFurusatoRecruitmentData[2];

export default function Page() {
  const { posts } = useSWRV2Posts();
  const { challenges } = useSWRV2Challenges(4);
  const { competitions } = useSWRV2Competitions(4);
  const { presentations } = useSWRV2Presentations(2);
  const { corporates } = useSWRV2Corporates(4);
  const { organizations } = useSWRV2Organizations(4);
  const { socialIssueCategories } = useSWRV2SocialIssueCategories();
  const { impact_cocreations } = useSWRV2ImpactCocreations();

  const carouselData = useMemo((): ComponentProps<typeof Carousel>['data'] => {
    if (isEmpty(competitions)) return [];

    return [
      {
        image: '/images/intilaq.png',
        url: '/reports/intilaq',
        title: '【インパクトレポート】東北社会起業家が生み出すインパクト',
        description: '',
      },
      // 最新募集
      {
        image: competitions[0].cover || '',
        url: `/comps/${competitions[0].id}`,
        title: competitionTitle(competitions[0]),
        description: '',
      },
      //広島県
      {
        image: RECRUITMENT.cover || '',
        url: `/corp-furusato/recruitments/${RECRUITMENT.id}`,
        title: RECRUITMENT.title,
        description: '',
      },
      //準新募集
      {
        image: competitions[1].cover || '',
        url: `/comps/${competitions[1].id}`,
        title: competitionTitle(competitions[1]),
        description: '',
      },
    ];
  }, [competitions]);

  const partnerData = useMemo((): ComponentProps<typeof Partner>['data'] => {
    if (!impact_cocreations) return [];

    return (
      [
        impact_cocreations.challenge && {
          bannerText: '提携パートナーが決まりました',
          description: impact_cocreations.challenge.title,
          descriptionLink: `/challenges/${impact_cocreations.challenge.id}`,
          leadOrg: {
            src: impact_cocreations.challenge.owner.data.logo || '',
          },
          partnerOrgs: [
            ...impact_cocreations.challenge.corporates.map((c) => ({ src: c.logo })),
            ...impact_cocreations.challenge.organizations.map((o) => ({ src: o.logo })),
          ],
        },
        impact_cocreations.competition && {
          bannerText: '寄付金給付先が決まりました',
          description: impact_cocreations.competition.title,
          descriptionLink: `/comps/${impact_cocreations.competition.id}`,
          leadOrg: { src: impact_cocreations.competition.corporate?.logo || '' },
          partnerOrgs: [{ src: impact_cocreations.competition.organization?.logo || '' }],
        },
        impact_cocreations.activity_report && {
          bannerText: '活動報告が掲載されました',
          description: impact_cocreations.activity_report.title,
          descriptionLink: `/activity-reports/${impact_cocreations.activity_report.id}`,
          leadOrg: { src: impact_cocreations.activity_report.corporate?.logo || '' },
          partnerOrgs: [{ src: impact_cocreations.activity_report.organization?.logo || '' }],
        },
      ] as (ComponentProps<typeof Partner>['data'][number] | undefined | null)[]
    ).filter(
      (
        d: ComponentProps<typeof Partner>['data'][number] | undefined | null,
      ): d is ComponentProps<typeof Partner>['data'][number] => d !== undefined && d !== null,
    );
  }, [impact_cocreations]);

  return (
    <Layout pageTitle={'サステナNet トップページ'} pageDescription={'サステナNet トップページ'}>
      <main className='flex w-full flex-col gap-4'>
        <Carousel data={carouselData} />
        <Partner data={partnerData} />
        <div className='mx-auto flex max-w-7xl px-4 pt-4 lg:px-24'>
          <div className='hidden w-56 lg:block'>
            <SearchSpo socialIssueCategories={socialIssueCategories || []} />
            <Area />
            <Link href={'/reports/intilaq'}>
              <Image src={IMG_INTILAQ} alt='intilaq' width={320} className='mt-4' />
            </Link>
          </div>
          <div className='flex flex-1 flex-col gap-y-20 pb-20 pl-0 lg:pl-6'>
            <AboveBanners />
            <Posts posts={posts?.slice(0, 3) || []} />
            <Challenge challenges={challenges ?? []} />
            <Competition competitions={competitions ?? []} />
            <Presentation presentations={presentations ?? []} />
            <Corp corporates={corporates ?? []} />
            <Spo organizations={organizations ?? []} />
            <BottomBanners />
          </div>
        </div>
      </main>
    </Layout>
  );
}
