import React, { ReactNode, useState, useEffect } from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface CarouselProps {
  slides: ReactNode[];
}

const Carousel: React.FC<CarouselProps> = ({ slides }) => {
  const [centerPadding, setCenterPadding] = useState('0px');

  useEffect(() => {
    const updatePadding = () => {
      const windowWidth = window.innerWidth;
      const paddingValue = Math.max(windowWidth / 2 - 360, 0); // 負の値を避けるために、0との比較をしている
      setCenterPadding(`${paddingValue}px`);
    };

    updatePadding();
    window.addEventListener('resize', updatePadding);

    return () => window.removeEventListener('resize', updatePadding);
  }, []);

  const settings = {
    dots: true, // スライド下部のドットの表示
    infinite: true, // 無限スクロール
    centerMode: true, // 中央を選択状態のスライドにする
    speed: 500, // スライドの切り替えスピード
    centerPadding: centerPadding,
    slidesToShow: 1, // 画面に全表示するスライドの数
    slidesToScroll: 1, // 一度にスクロールするスライドの数
    autoplay: true, // スライドの自動再生
    autoplaySpeed: 2000, // 自動再生時のスライドの切り替え時間
  };

  return (
    <div className='overflow-hidden pb-24'>
      <style>
        {/* ドットのサイズを大きくするためスタイルを上書き */}
        {`
          .slick-dots li button:before {
            font-size: 20px; /* ドットの大きさを変更 */
            color: #E7E9E8; /* ドットの非選択時の色 */
            opacity: 1; /* ドットの不透明度 */
            border-radius: 50%; /* ドットを円形に */
            content: ''; /* 通常のドット表示を上書きするために必要 */
            width: 16px; /* ドットの直径 */
            height: 16px; /* ドットの直径 */
            background-color: #E7E9E8; /* ドットの背景色 */
          }
          .slick-dots li.slick-active button:before {
            color: #0859A5; /* 選択されたドットの色 */
            background-color: #0859A5; /* 選択されたドットの背景色 */
          }
          .slick-dots li {
            margin: 0 12px; /* 各ドットの左右のマージンを12pxに設定し、合計で24pxの間隔を作る */
          }
          .slick-dots {
            bottom: -49px; /* ドットをデフォルト（-25px）より 24px 下に配置 */
          }
        `}
      </style>
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div key={index} className='px-4'>
            {slide}
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
