import { ApiV2Presentation } from '~/api/v2/presentations/get-type';
import { CardPresentation } from '~/components/Card/CardPresentation';
import SectionHeader from '~/components/SectionHeader/SectionHeader';
import { PresentationT } from '~/state/presentation';

const Card = ({ presentation }: { presentation: PresentationT | ApiV2Presentation }) => {
  return (
    <CardPresentation
      link={`/presentations/${presentation.id}`}
      companyName={presentation.organization.name}
      companyLogo={presentation.organization.logo || '/icons/corp-icon.png'}
      title={presentation.title}
      src={presentation.thumbnail}
    />
  );
};

export const Presentation = ({
  presentations,
}: {
  presentations: PresentationT[] | ApiV2Presentation[];
}) => {
  return (
    <div className='flex flex-col items-center gap-6'>
      <SectionHeader
        title='プレゼンテーション'
        description='NPO・社会的事業者の取り組みについて動画で知ろう'
        href='/presentations'
      />
      <div className='flex flex-col flex-wrap justify-center gap-4 lg:flex-row'>
        {presentations.map((presentation) => (
          <Card key={presentation.id} presentation={presentation} />
        ))}
      </div>
    </div>
  );
};
