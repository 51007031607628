import MainBanners from './mainBanners';

export const BottomBanners = () => {
  return (
    <>
      <div>
        <div className='pb-4 text-title-strong-x-small text-regular'>
          世の中のさまざまな社会課題を、
          <br />
          テーマや地域別に見たい方は以下ご覧ください。
        </div>
        <MainBanners
          banners={[
            {
              src: '/images/top/banners/general-social-issues.png',
              alt: '社会課題一覧',
              link: '/social-issues/general-social-issues',
            },
            {
              src: '/images/top/banners/local-social-issues.png',
              alt: '地域課題一覧',
              link: '/social-issues/local-social-issues',
            },
          ]}
        />
      </div>
      <div>
        <div className='pb-4 text-title-strong-x-small text-regular'>
          サステナビリティ活動でお悩みだったり、
          <br />
          他社事例を見たい方は以下ご覧ください。
        </div>
        <MainBanners
          banners={[
            {
              src: '/images/top/banners/iinkai.png',
              alt: 'サステナ委員会',
              link: 'https://lp.ichicommons.com/iinkai',
            },
            {
              src: '/images/top/banners/press.png',
              alt: 'サステナPress',
              link: 'https://press.ichicommons.com',
            },
          ]}
        />
      </div>
    </>
  );
};
