import { ApiV2SocialIssueCategory } from '~/api/v2/social_issue_categories/get-type';
import MenuCategoryItem from '~/components/MenuCategoryItem/MenuCategoryItem';

export const SearchSpo = ({
  socialIssueCategories,
}: {
  socialIssueCategories: ApiV2SocialIssueCategory[];
}) => {
  return (
    <>
      <div className='mb-4 text-title-strong-x-small text-accent'>NPO・社会的事業者を探す</div>
      <div className='justify-center gap-4'>
        {socialIssueCategories.map((category, index) => (
          <MenuCategoryItem
            key={index}
            title={category.name}
            href={`/orgs?keyword=${category.name}`}
          />
        ))}
      </div>
      <hr className='border-1 border-gray-300 my-6' />
    </>
  );
};
