import useSWR from 'swr';

import { SWR_GLOBAL_CONFIG } from '~/api/_constant';
import { request } from '~/api/_utils/request';

import { ApiV2Corporate } from './get-type';

const getPath = (limit?: number) => (limit ? `/v2/corporates?limit=${limit}` : '/v2/corporates');

export async function apiGetV2Corporates(limit?: number) {
  const response = await request.GET<{ corporates: ApiV2Corporate[] }>(getPath(limit));
  return response.corporates;
}

export function useSWRV2Corporates(limit?: number) {
  const swr = useSWR(
    () => getPath(limit),
    () => apiGetV2Corporates(limit),
    SWR_GLOBAL_CONFIG,
  );
  return { ...swr, corporates: swr.data };
}
