import LinkGroup from '~/components/LinkGroup/LinkGroup';
import { linkGroupData } from '~/const';

export const Area = () => {
  return (
    <div className='mb-8'>
      <div className='text-title-strong-x-small text-accent'>地域を支援</div>
      <div className='my-4 flex flex-row flex-wrap gap-4'>
        {linkGroupData.map((data, index) => (
          <LinkGroup
            key={index}
            groupName={data.groupName}
            links={data.links.map((d) => ({
              name: d.name,
              href: `/orgs?keyword=${d.name}`,
            }))}
          />
        ))}
      </div>
    </div>
  );
};
