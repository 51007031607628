import React from 'react';

import { Button } from '../Button/Button';

type Link = {
  name: string;
  href: string;
};

interface LinkGroupProps {
  groupName: string;
  links: Link[];
}

const LinkGroup: React.FC<LinkGroupProps> = ({ groupName, links }) => {
  return (
    <div className='flex w-56 flex-col items-start'>
      <div className='pb-2 text-label-strong-medium text-regular'>{groupName}</div>
      <div className='flex w-full flex-row flex-wrap content-start items-start gap-s p-0'>
        {links.map((link, index) => (
          <Button
            key={index}
            variant='link'
            isShowArrowIcon={false}
            isIconOnly={false}
            link={{ href: link.href }}
            size='small'
          >
            {link.name}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default LinkGroup;
