import type { ApiCompetition } from '~/api/v2/competitions/get-type';

import type { CorporateT } from '../corporate';
import type { OrgT } from '../org';
import type { PresentationT } from '../presentation';
import type { SocialIssueT } from '../socialIssue';

/** @deprecated use `ApiCompetition` or `ApiCompetitionShow` */
export type CompetitionT = {
  id: number;
  title?: string;
  cover: string;
  corporate: CorporateT;
  social_issue: SocialIssueT;
  amount: string | number;
  schedules: string[];
  status: 'open' | 'voting' | 'finished';
  updated_at: string;
  presentations: PresentationT[];
  most_voted_organization?: OrgT;
  vote_count_visible?: boolean;
};

export const competitionStatus = (status: string) => {
  switch (status) {
    case 'open':
      return 'SPO団体\n募集中';
    case 'voting':
      return '投票\n期間中';
    case 'finished':
      return '投票\n終了';
    default:
      return '';
  }
};

export const competitionTitle = (project: CompetitionT | ApiCompetition) => {
  const title = project.title;
  if (title) return title;

  if (project.social_issue.type === 'CitySocialIssue') {
    return `「${project.social_issue.name}」で活動する寄付先を募集します。`;
  }

  return `「${project.social_issue.name}」をテーマにした寄付先となる協力団体を${
    project.social_issue.type === 'GeneralSocialIssue' ? '広く全国から' : ''
  }募集します。`;
};
